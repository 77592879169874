.server-grid {
    min-height: 10vh;
  }
  
  .server-overview-wrapper {
    background: rgb(73, 73, 73);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }
  
  .server-overview-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10%;
    background: linear-gradient(to bottom, rgba(0,0,0,0.05), transparent);
    opacity: 0;
    transition: opacity 0.2s ease;
    cursor: move; /* Change cursor to indicate draggable area */
  }
  
  .server-overview-wrapper:hover::before {
    opacity: 1;
  }
  
  .server-overview-wrapper > * {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .server-overview-wrapper:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .server-overview-wrapper.is-dragging {
    opacity: 0.7;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
    z-index: 10;
  }
  
  @keyframes pop {
    0% { transform: scale(1); }
    50% { transform: scale(1.02); }
    100% { transform: scale(1); }
  }
  
  .server-overview-wrapper:not(.is-dragging) {
    animation: pop 0.3s ease;
  }
  
  /* New styles for cursor feedback */
  .server-overview-wrapper {
    cursor: default;
  }
  
  .server-overview-wrapper:hover {
    cursor: grab;
  }
  
  .server-overview-wrapper:active {
    cursor: grabbing;
  }