.server-overview-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1rem;
    padding: 1rem;
    background: linear-gradient(90deg, #000000, #0109a9);
    width: 100%;
  }
  @media (max-width: 767px) {
  .server-overview-grid {
    width: max-content;
  }
}
  
  .server-overview-card {
    width: 100%;
    max-width: 100%;
    background-color: #1f2937;
    border-radius: 0.5rem;
    padding: 1rem;
    color: white;
    box-sizing: border-box;
  }
  
  .server-overview-card h2 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .hdd-details {
    margin-top: 0.3rem;
  }
  
  .hdd-item {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
