body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  transition: background-color 0.3s, color 0.3s;
}

body.light-theme {
  background-color: #fff;
  color: #333;
}

body.dark-theme {
  background-color: #333;
  color: #fff;
}